/* 전체 배경색 지정 */
body {
  background-color: #f9f9f9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 기존 h2-control 스타일 */
.h2-control {
  font-size: 40px; /* 기본 폰트 크기 */
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left; /* 텍스트를 왼쪽으로 정렬 */
}

/* App-header 스타일 수정 */
.App-header {
  display: flex;
  justify-content: space-between; /* 제목과 로그인 요소를 좌우로 배치 */
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1; /* 배경 색상 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

/* 로그인 입력창 및 버튼 스타일 */
.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.input-fields-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 80%; /* 입력창 너비를 50%로 조정 */
}

.input-fields-container input {
  width: 80%; /* 입력창 너비를 줄임 */
  padding: 8px; /* 패딩을 줄여 크기 축소 */
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px; /* 폰트 크기를 줄임 */
}

.login-button {
  width: 40%; /* 버튼 너비 조정 */
  padding: 10px; /* 패딩을 줄임 */
  font-size: 20px; /* 버튼 폰트 크기를 줄임 */
  margin-bottom: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.login-button:hover {
  background-color: #0056b3;
}


/* 기타 인증 버튼 스타일 (아이디 찾기, 비밀번호 재설정, 회원가입) */
.auth-buttons a button {
  padding: 8px 10px; /* 패딩을 줄임 */
  border: 1px solid black;
  background-color: #007bff;
  color: rgb(39, 35, 35);
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px; /* 폰트 크기를 줄임 */
}

.auth-buttons button:hover {
  background-color: #0056b3;
}

/* 로그인 버튼 및 다른 인증 버튼을 오른쪽 정렬 */
.auth-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
}

.auth-buttons a {
  text-decoration: none;
}

.auth-buttons a button {
  background-color: #fbfcfb;
}

.auth-buttons a button:hover {
  background-color: #a5b1a7;
}


/* 메인 컨테이너에 배경 이미지 지정 */
.main-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #000;
  padding: 3px 1px 10% 1px;
  border-radius: 10px;
  margin: 10px 15%;
}

.header {
  margin-bottom: 20px;
}

.main-content {
  border: 2px solid #000;
  padding: 20px;
  border-radius: 10px;
}

.search-bar-container,
.table-container {
  margin-bottom: 20px;
}

.pagination {
  text-align: center;
}

/* 네비게이션 스타일 */
.nav-container-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nav-container {
  flex: 1;
  margin-right: 1px;
}

.nav-item {
  align-items: center;
  text-decoration: none;
  color: #000;
}

.nav-image {
  width: 90%;
  height: 80%;
  margin: 0px 0px 0px 3%;
}

.nav-text {
  width: 90%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin: 1px 1px 1px 10px;
  padding: 1px 1px 1px 1px;
  border-radius: 5px;
  text-align: left;
}

.nav-text p {
  font-size: 15px;
  margin: 1px;
}

/* 광고 배너 컨테이너 스타일 (왼쪽) */
.left-ad-container {
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-ad-container a {
  margin-bottom: 10%;
}

.left-ad-container img {
  width: 100%;
  height: auto;
  max-width: 160px;
}

/* 광고 배너 컨테이너 스타일 (오른쪽) */
.right-ad-container {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-ad-container a {
  margin-bottom: 30px;
}

.right-ad-container img {
  width: 100%;
  height: auto;
  max-width: 160px;
}

/* 네비게이션 스타일 */
.category-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 0px 0px 10px;
  width: 100%; /* 너비를 100%로 설정 */
  height: 100px; /* 높이를 100px로 설정 */
  padding: 1px 0;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category-nav a {
  text-decoration: none;
  color: #333;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.category-nav a:hover {
  background-color: #ddd;
}

.category-nav a.active {
  background-color: #007bff;
  color: white;
}

