/* 자유게시판 전체 컨테이너 */
.freeboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 제목 */
h2 {
  text-align: center;
  font-size: 26px;
  color: #333;
  margin-bottom: 20px;
}

/* 글 목록 테이블 */
.freeboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed; /* 테이블 레이아웃 고정 */
}

/* 텍스트 줄바꿈 방지 */
.freeboard-table th, 
.freeboard-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: normal
}

.freeboard-table th {
  background-color: #1e47b6;
  cursor: pointer;
}

.freeboard-table th:hover {
  background-color: #e0e0e0;
}

/* 빈 행에도 분류선 유지 */
.freeboard-table .empty-row td {
  border: 1px solid #ddd !important;
  background-color: #f9f9f9 !important;
  height: 50px !important;
}

/* 버튼 스타일 */
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* 글쓰기 버튼 컨테이너 */
.write-post-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/* 댓글 섹션 */
.comments-section {
  margin-top: 30px;
}

.comments-section h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.comment {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.comment p {
  margin: 5px 0;
}

.comment .comment-author {
  font-weight: bold;
  color: #555;
}

.comment .comment-date {
  font-size: 12px;
  color: #999;
}

.comment .comment-actions {
  margin-top: 10px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.comment-submit-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
}

.comment-submit-button:hover {
  background-color: #218838;
}

/* 게시글 제목 및 내용 스타일 */
.post-detail {
  margin-bottom: 20px;
}

.post-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.post-content {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* 수정/삭제 버튼 */
.post-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
