/* src/css/gy.css */

.gy-container {
    text-align: center;
    padding: 20px;
  }
  
  .gy-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .gy-announcement {
    font-size: 18px;
    margin: 20px 0;
    color: #333;
  }
  