/* 전체 컨테이너 */
.Regionpage-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 테이블 스타일 */
.ranking-table {
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  table-layout: fixed;
}

/* 테이블 헤더 */
.ranking-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  white-space: nowrap;
  cursor: pointer;
}

.ranking-table th:hover {
  background-color: #0056b3;
}

/* 테이블 셀 스타일 */
.ranking-table td {
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50px;
}

/* 빈 행 스타일 */
.ranking-table .empty-row td {
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* 페이지네이션 버튼 */
.pagination button {
  padding: 8px 12px;
  margin: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0056b3;
}

.pagination button:hover {
  background-color: #0056b3;
}
