/* Search bar styles */
.search-bar-container {
  display: flex;
  justify-content: center;
  padding: 0 10px; /* 좌우 여백 추가 */
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center; /* 중앙 정렬 */
  flex-wrap: wrap; /* 가로 정렬 유지 */
}

.search-bar__select {
  font-size: 1em; /* 크기 1.5배 키우기 */
  padding: 10px;
  margin: 10px; /* 여백을 동일하게 설정 */
  width: 150px; /* 선택 입력칸의 기본 너비 설정 */
  max-width: 100%; /* 반응형을 위해 최대 너비 설정 */
}

.search-bar__input {
  font-size: 1em; /* 크기 1.5배 키우기 */
  padding: 10px;
  margin: 10px; /* 여백을 동일하게 설정 */
  width: 200px; /* 입력칸의 기본 너비 설정 */
  max-width: 100%; /* 반응형을 위해 최대 너비 설정 */
}

.search-bar__select option {
  font-size: 1em; /* 크기 1.5배 키우기 */
  padding: 10px;
}

.search-bar button {
  font-size: 1em; /* 크기 1.5배 키우기 */
  padding: 10px 10px;
  margin: 10px; /* 여백을 동일하게 설정 */
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  width: 100px; /* 버튼의 기본 너비 설정 */
  max-width: 100%; /* 반응형을 위해 최대 너비 설정 */
}

.search-bar button:hover {
  background-color: #0056b3;
}
/* 반응형 디자인 */
@media (max-width: 1000px) {
  .search-bar-container {
    flex-direction: column; /* 모바일에서 컨테이너를 세로 정렬 */
    align-items: center; /* 중앙 정렬 */
  }

  .search-bar {
    flex-direction: row; /* 가로 정렬 유지 */
    justify-content: center; /* 중앙 정렬 */
    flex-wrap: nowrap; /* 줄 바꿈 없이 한 줄로 배치 */
  }

  .search-bar__select {
    font-size: 0.8em; /* 모바일 화면에서 검색 바 크기를 줄임 */
    width: 30%; /* 검색어 입력칸의 너비를 줄임 */
    margin: 1px; /* 위아래 여백 설정 */
  }
  .search-bar__input {
    font-size: 0.8em; /* 모바일 화면에서 검색 바 크기를 줄임 */
    width: 30%; /* 검색어 입력칸의 너비를 줄임 */
    margin: 1px; /* 위아래 여백 설정 */
  }

  .search-bar button {
    font-size: 1em; /* 모바일 화면에서 버튼 크기를 줄임 */
    padding: 0px; /* 모바일 화면에서 패딩을 줄임 */
    width: 30%;
    height: 30px;
  }

  /* 기타 반응형 스타일 */
  .table-container {
    margin: 0 10px; /* 모바일 화면에서 좌우 여백을 설정 */
  }

  table {
    font-size: 0.9em; /* 모바일 화면에서 폰트 크기를 줄임 */
  }

  th,
  td {
    padding: 5px; /* 모바일 화면에서 패딩을 줄임 */
  }

  th {
    font-size: 1em; /* 모바일 화면에서 헤더 폰트 크기를 줄임 */
  }

  .pagination button {
    font-size: 1em; /* 모바일 화면에서 페이지네이션 버튼 크기를 줄임 */
  }
}


