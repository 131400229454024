/* 전체 컨테이너 스타일 */
.user-profile-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* 제목 스타일 */
.user-profile-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* 에러 메시지 스타일 */
.user-profile-container p {
  color: red;
  text-align: center;
}

/* 정보와 수정 섹션 스타일 */
.user-profile-container div {
  margin-bottom: 15px;
}

/* 라벨과 입력 필드 스타일 */
.user-profile-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.user-profile-container input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* 버튼 스타일 */
.user-profile-container button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.user-profile-container button:hover {
  background-color: #45a049;
}

/* 취소 버튼 스타일 */
.user-profile-container button.cancel-btn {
  background-color: #f44336;
}

.user-profile-container button.cancel-btn:hover {
  background-color: #d32f2f;
}

/* 로딩 메시지 스타일 */
.user-profile-container .loading-message {
  text-align: center;
  font-size: 18px;
  color: #666;
}
