/* 공지사항 전체 컨테이너 */
.notice-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 공지사항 리스트 테이블 */
.notice-table {
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  table-layout: fixed; /* 고정된 셀 너비 */
}

/* 공지사항 테이블 헤더 */
.notice-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  white-space: nowrap;
  cursor: pointer;
}

.notice-table th:hover {
  background-color: #0056b3;
}

/* 공지사항 테이블 셀 */
.notice-table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50px;
}

/* 공지사항 상세 페이지 */
.notice-detail-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.notice-detail-header {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.notice-detail-info {
  font-size: 16px;
  color: #888;
  margin-bottom: 20px;
}

.notice-detail-content {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
}

/* 공지사항 상세 페이지의 뒤로 가기 버튼 */
.back-link-container {
  text-align: center;
  margin-top: 30px;
}

.back-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.back-link:hover {
  background-color: #0056b3;
}
