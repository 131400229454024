/* 테이블 스타일 */

.table-container {
  width: 100% !important;  /* 컨테이너가 페이지 너비에 맞도록 설정 */
  max-width: 100% !important; /* 테이블이 부모의 크기를 초과하지 않도록 설정 */
  overflow-x: hidden !important; /* 가로 스크롤을 방지 */
}

table {
  width: 100% !important; /* 테이블이 부모 요소의 크기에 맞게 확장 */
  max-width: 100% !important; /* 테이블이 부모 요소의 크기를 넘지 않도록 설정 */
  table-layout: auto !important; /* 테이블이 콘텐츠에 따라 유연하게 조정 */
  border-collapse: collapse !important; /* 셀 간의 경계가 없어지지 않도록 설정 */
  border: 1px solid #ddd !important; /* 테이블 외곽선 추가 */
  background-color: #f8f8f8 !important;
  background-size: cover !important;
  background-position: center !important;
}

th, td {
  border: 1px solid #ddd !important; /* 셀 구분선을 명확히 표시 */
  padding: 10px !important; /* 패딩 추가 */
  text-align: center !important; /* 텍스트 가운데 정렬 */
  white-space: nowrap !important; /* 텍스트 줄바꿈 방지 */
}

th {
  background-color: rgb(39, 100, 231) !important; /* 불투명하게 설정 */
  position: sticky !important;
  top: 0 !important;
  z-index: 2 !important;
  white-space: pre-line !important;
}

th:nth-child, td:nth-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background-color: rgba(248, 248, 248, 1) !important; /* 불투명하게 설정 */
}

th:first-child {
  z-index: 4 !important;
}

td:first-child {
  z-index: 1 !important;
}

/* 점수별 배경색 클래스 */
.score-30 {
  background-color: rgba(212, 248, 232, 0.8) !important;
}
.score-50 {
  background-color: rgba(230, 212, 248, 0.8) !important;
}
.score-80 {
  background-color: rgba(248, 240, 212, 0.8) !important;
}
.score-100 {
  background-color: rgba(248, 212, 212, 0.8) !important;
}
.score-120 {
  background-color: rgba(212, 240, 248, 0.8) !important;
}
.score-150 {
  background-color: rgba(212, 230, 248, 0.8) !important;
}
.score-200 {
  background-color: rgba(212, 248, 212, 0.8) !important;
}
.score-250 {
  background-color: rgba(248, 212, 230, 0.8) !important;
}
.score-300 {
  background-color: rgba(240, 248, 212, 0.8) !important;
}
.score-400 {
  background-color: rgba(248, 230, 212, 0.8) !important;
}
.score-500 {
  background-color: rgba(248, 212, 240, 0.8) !important;
}
.score-700 {
  background-color: rgba(240, 212, 248, 0.8) !important;
}
.score-1000 {
  background-color: rgba(248, 212, 248, 0.8) !important;
}
.score-1500 {
  background-color: rgba(212, 248, 240, 0.8) !important;
}
.score-2000 {
  background-color: rgba(212, 248, 248, 0.8) !important;
}

.rank-image {
  width: 100% !important;
  max-width: 30px !important; /* 최대 너비를 설정하여 셀 크기를 넘어가지 않도록 함 */
  height: auto !important; /* 높이를 자동으로 조정 */
  object-fit: cover !important;
  border-radius: 50% !important;
}
