/* 컨테이너 스타일 */
.promotion-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 제목 스타일 */
.promotion-container h2 {
  text-align: center;
  font-size: 26px;
  color: #333;
  margin-bottom: 20px;
}

/* 폼 그룹 스타일 */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.form-group textarea {
  height: 150px;
  resize: none;
}

/* 버튼 스타일 */
.submit-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* 삭제 및 수정 버튼 스타일 */
.promotion-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.promotion-actions button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.promotion-actions button.delete {
  background-color: #dc3545;
}

.promotion-actions button:hover {
  opacity: 0.9;
}

/* 신청자 목록 스타일 */
.applicant-list {
  margin-top: 30px;
  padding: 15px;
  background-color: #e9ecef;
  border-radius: 8px;
}

.applicant-list ul {
  list-style: none;
  padding: 0;
}

.applicant-list li {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.applicant-list li:last-child {
  border-bottom: none;
}

.applicant-list p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

/* 에러 메시지 스타일 */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}
