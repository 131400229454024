.pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0px 30px 0px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:hover {
  background-color: #ddd;
}

@media (max-width: 1000px) {
  .pagination {
    display: flex;
    justify-content: center;
    margin: 10px 0px 30px 0px;
    font-size: 0.8em;
  }

  .pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 0.8em;
  }

  .pagination button.active {
    background-color: #007bff;
    color: white;
  }

  .pagination button:hover {
    background-color: #ddd;
  }
}
