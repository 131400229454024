.tournament-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.players-input {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4개의 플레이어를 가로로 나열 */
  gap: 10px;
  margin-bottom: 20px;
}

.player-input {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.player-input select,
.player-input input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%; /* 입력 필드의 너비를 100%로 설정 */
}

.player-input p {
  margin: 5px 0;
  color: #555;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.round {
  margin-top: 20px;
}

.round h2 {
  text-align: center;
  color: #555;
  font-size: 20px;
  margin-bottom: 10px;
}

.match {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px; /* 구분선을 위한 패딩 추가 */
  border-bottom: 2px solid #ddd; /* 구분선을 추가 */
}

.player {
  width: 45%;
  text-align: center;
}

.player p {
  margin: 5px 0;
}

.vs {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.final-winner {
  margin-top: 30px;
  padding: 15px;
  background-color: #e0ffe0;
  border: 2px solid #5cb85c;
  border-radius: 8px;
  text-align: center;
}

.final-winner h2 {
  color: #5cb85c;
  font-size: 24px;
  margin-bottom: 10px;
}

.final-winner p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
}


/*tnp.css*/
.tournament-type-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tournament-type-container h2 {
  text-align: center;
  color: #333;
  font-size: 26px;
  margin-bottom: 20px;
}

.tournament-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.tournament-button {
  padding: 12px 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.tournament-button:hover {
  background-color: #0056b3;
}

.promotion-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.promotion-button:hover {
  background-color: #218838;
}

.promotion-board {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.promotion-board h3 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}

.promotion-board table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.promotion-board th, .promotion-board td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.promotion-board th {
  cursor: pointer;
  background-color: #320fcf;
}

.promotion-board th:hover {
  background-color: #e9ecef;
}

.promotion-board td a {
  color: #007bff;
  text-decoration: none;
}

.promotion-board td a:hover {
  text-decoration: underline;
}

