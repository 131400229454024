.signup-form {
    display: flex;
    flex-direction: column; /* 세로 방향 정렬 */
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .signup-form label {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .signup-form input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .signup-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-form button:hover {
    background-color: #0056b3;
  }
  
  .signup-form h2 {
    margin-bottom: 20px;
  }
  
  .signup-form .message {
    margin-bottom: 10px;
    color: red;
    font-weight: bold; /* 강조 */
  }
  

  .signup-form select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white; /* 드롭다운 배경 */
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup-form select:focus {
    outline: none;
    border-color: #007bff; /* 선택 시 테두리 색상 변경 */
  }
  
  .signup-form .error-message {
    color: red;
  }
  
  .signup-form .success-message {
    color: green;
  }
  
  