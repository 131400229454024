.members-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    min-width: 600px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .members-table th,
  .members-table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .members-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    cursor: pointer;
  }
  
  .members-table th .arrow {
    margin-left: 5px;
    display: inline-block;
    opacity: 0.5;
  }
  
  .members-table th .arrow::after {
    content: '▲';
  }
  
  .members-table th .arrow.desc::after {
    content: '▼';
  }
  
  .members-table tr {
    border-bottom: 1px solid #ddd;
  }
  
  .members-table tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .members-table tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .members-table button {
    background-color: #ff4c4c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .members-table button:hover {
    background-color: #ff1a1a;
  }
  