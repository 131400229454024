.ad-container {
  text-align: center;
  margin: 20px;
  position: relative; /* 부모 컨테이너에 상대적인 위치 설정 */
}

.ad-content {
  border: 1px solid #ccc;
  padding: 20px;
  display: inline-block;
  position: relative; /* 자식 요소에 대한 기준 위치 설정 */
}

.ad-content img {
  max-width: 100%;
  height: auto;
  width: 200px; /* 원하는 너비로 설정 */
  height: 200px; /* 원하는 높이로 설정 */
  position: absolute; /* 절대 위치로 설정하여 움직일 수 있도록 함 */
}

/* 모바일에서 이미지 크기 조정 */
@media (max-width: 1000px) {
  .ad-content img {
    width: 70px; /* 모바일에서 원하는 너비로 설정 */
    height: 70px; /* 모바일에서 원하는 높이로 설정 */
  }
}
