/* 전체 컨테이너 스타일 */
.attendance-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* 출석체크 제목 */
  .attendance-container h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* 포인트 표시 */
  .attendance-container p {
    font-size: 18px;
    color: #555;
  }
  
  /* 에러 메시지 */
  .error-message {
    color: red;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* 출석체크 버튼 */
  .attendance-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .attendance-button:hover {
    background-color: #0056b3;
  }
  
  /* 캘린더 스타일 */
  .react-calendar {
    width: 100%;
    max-width: 500px;
    margin: 30px auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    font-family: 'Arial', sans-serif;
  }
  
  .react-calendar__tile {
    height: 50px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .react-calendar__tile:hover {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile--active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .react-calendar__tile--now {
    background-color: #ffc107;
    color: white;
    font-weight: bold;
  }
  
  .react-calendar__tile p {
    margin: 0;
  }
  
  /* 출석 체크 표시 (✔) */
  .tile-check {
    color: green;
    font-size: 18px;
    font-weight: bold;
  }
  